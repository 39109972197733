<script>
export default {
  name: 'createCalendarEvent',
  props: {
    event: { required: false, type: Object, default: () => {} }
  },
  data () {
    return {
      loading: false,
      finish: false,
      showError: false,
      titre: null,
      description: null,
      menuDateDebut: false,
      menuDateFin: false,
      dateDebut: null,
      dateFin: null,
      fullDay: false,
      heureDebut: null,
      heureFin: null,
      selectedCalendar: null
    }
  },
  computed: {
    showCreateEvent: {
      set (val) { this.$store.commit('Dialogs/setCreateEvent', val) },
      get () { return this.$store.state.Dialogs.showCreateEvent }
    },
    calendarsAvailable () {
      return this.$store.state.User.groupes.map((e) => { return { id: e.calendrier.id, titre: e.calendrier.titre, calendrier: e.calendrier, salle: { id: e.id, titre: e.titre } } })
    }
  },
  watch: {
  },
  methods: {
    reset () {
      this.code = null
      this.loading = false
      this.showError = false
      this.finish = false
    },
    async createEvent () {
      this.showError = false
      if (this.selectedCalendar && this.titre && this.dateDebut && this.dateFin) {
        await this.$apollo.mutate({
          mutation: require('@/graphql/mutations/v2/createCalendrierElement.gql'),
          variables: {
            calendrier: this.selectedCalendar.id,
            salles: [this.selectedCalendar.salle.id],
            attrs: {
              titre: this.titre,
              description: this.description,
              debutAt: this.dateDebut,
              finAt: this.dateFin
            }
          }
        }).then(({ data }) => {
          if (!data.createCalendrierElement || data.createCalendrierElement.errors) {
            console.error('erreur')
            this.showError = true
            this.loading = false
          } else {
            this.loading = false
            this.finish = true
            this.$emit('refetch')
            this.showCreateEvent = false
          }
        })
      } else {
        this.showError = true
      }
    }
  }
}
</script>
<template lang="pug">
v-dialog(v-model='showCreateEvent', max-width='600',content-class='custom-dialog', persistent)
  v-card.light()
    v-hover(v-slot:default="{ hover }")
      v-btn.dialog-button(@click='showCreateEvent = !showCreateEvent', @click.native='reset', depressed, fab, x-large, color='secondary')
        font-awesome-icon.f3(:icon="['fas', 'times']")
    v-card-title.dialog-title
      span(v-if='event') Modifier l'événement
      span(v-else) Créer un événement
    v-card-text.relative()
      template(v-if='event')
        v-form(ref='editForm')
          v-select(v-model='selectedCalendar', placeholder='Sélectionner le calendrier', solo, outlined, flat, :items='calendarsAvailable')
          v-text-field(color='secondary', placeholder="Titre de l'événement", solo, v-model='titre', outlined, flat)
          v-textarea(color='secondary', placeholder="Description", solo, v-model='description', outlined, flat)
      template(v-else)
        v-form(ref='emptyForm')
          v-select(v-model='selectedCalendar', placeholder='Sélectionner le calendrier', solo, outlined, flat, :items='calendarsAvailable', item-text='titre', return-object)
          v-text-field(color='secondary', placeholder="Titre de l'événement", solo, v-model='titre', outlined, flat)
          v-textarea(color='secondary', placeholder="Description", solo, v-model='description', outlined, flat)
          div.flex
            v-menu(ref='menuDebut', v-model='menuDateDebut', :close-on-content-click='false', :return-value.sync='dateDebut', transition='scale-transition', offset-y, min-width='290px')
              template(v-slot:activator='{ on, attrs }')
                v-text-field(dense, v-model='dateDebut', solo, outlined, flat, placeholder='Debut', label='Date de debut', prepend-icon='mdi-calendar', readonly, v-bind='attrs', v-on='on')
              v-date-picker(v-model='dateDebut', no-title, scrollable, color='primary')
                v-spacer
                v-btn(text, color='primary', @click='menuDateDebut = false') Annuler
                v-btn(text, color='primary', @click='$refs.menuDebut.save(dateDebut)') Confirmer

            v-menu(ref='menuFin', v-model='menuDateFin', :close-on-content-click='false', :return-value.sync='dateFin', transition='scale-transition', offset-y, min-width='290px')
              template(v-slot:activator='{ on, attrs }')
                v-text-field(dense, v-model='dateFin', solo, outlined, flat, placeholder='Fin', label='Date de fin', readonly, v-bind='attrs', v-on='on', style='margin-left:20px;')
              v-date-picker(v-model='dateFin', no-title, scrollable, color='primary')
                v-spacer
                v-btn(text, color='primary', @click='menuDateFin = false') Annuler
                v-btn(text, color='primary', @click='$refs.menuFin.save(dateFin)') Confirmer
          div.flex
            v-text-field(dense, v-model='heureDebut', solo, outlined, flat, placeholder='Heure de début', label='Heure de début', prepend-icon='mdi-calendar-clock', :disabled='fullDay', hide-details)
            v-text-field(dense, v-model='heureFin', solo, outlined, flat, placeholder='Heure de fin', label='Heure de fin', style='margin-left:20px;', :disabled='fullDay', hide-details)
          v-checkbox(v-model='fullDay', label='Toute la journée')

        v-alert(type="error", v-if='showError') Valider la date fin supérieur date debut
    v-btn(@click='createEvent', color='primary', block, x-large)
      span.f4 Créer l'événement
</template>
<style lang='sass'>

</style>
