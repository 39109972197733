<script>
import CreateEvent from '@/components/dialogs/calendrier/createEvent.vue'

export default {
  name: 'eventCard',
  components: {
    CreateEvent
  },
  props: {
    selectedEvent: {
      required: true,
      type: Object
    }
  },
  computed: {
    showCreateEvent: {
      set (val) {
        this.$store.commit('Dialogs/setCreateEvent', val)
      },
      get () {
        return this.$store.state.Dialogs.showCreateEvent
      }
    }
  },
  methods: {
    deleteEvent () {
      this.$store.commit('Dialogs/setElemToDelete', this.selectedEvent)
      this.$store.commit('Dialogs/setDeleteEvent', true)
    },
    getDate (date) {
      return new Date(date).toLocaleDateString('fr-CA', {
        month: 'long',
        day: 'numeric'
      })
    },
    getTime (date) {
      return new Date(date).toLocaleTimeString('fr-CA', {
        hour: '2-digit',
        minute: '2-digit',
        hour12: false
      })
    }
  }
}
</script>

<template lang='pug'>
v-card.event-card(color='grey lighten-4', flat, :tile='$store.state.App.mobileTemplate')
  //- pre {{selectedEvent}}

  v-toolbar(:color='selectedEvent.color', dark, flat, dense)
    div
      div.f7(v-if='selectedEvent.calendrier && selectedEvent.calendrier.titre') {{selectedEvent.calendrier.titre}}
      v-toolbar-title(v-html='selectedEvent.name', style='line-height:1.15;')
    v-spacer
    //- v-btn(small, icon @click='showCreateEvent = !showCreateEvent')
      font-awesome-icon.f5(:icon="['fad', 'pen']")
    v-menu(offset-y)
      template(v-slot:activator='{ on }')
        v-btn.mr1(icon,small, @click.prevent.stop='', v-on='on')
          font-awesome-icon.f6(:icon="['fas', 'ellipsis-v']")
      v-card.pv2
        v-list(dense, nav)
          v-list-item(@click='', disabled)
            font-awesome-icon.f6.mr2(:icon="['fad', 'share-square']")
            v-list-item-content
              v-list-item-title Partager l'événement
          v-list-item(@click='', disabled)
            font-awesome-icon.f6.mr2(:icon="['fad', 'pen']")
            v-list-item-content
              v-list-item-title Répéter l'événement...
          v-list-item(@click='deleteEvent')
            font-awesome-icon.f6.mr2(:icon="['fad', 'trash-alt']")
            v-list-item-content
              v-list-item-title Supprimer l'événement
          v-list-group(@click.stop='', color='secondary', v-if='$store.state.Exercices.calendriers')
            template(v-slot:activator='')
              v-list-item-content(, disabled)
                v-list-item-title Déplacer vers...
            template(v-for='(cal, index) in $store.state.Exercices.calendriers')
              v-list-item(color='red', :key='index', @click='')
                v-list-item-content
                  v-list-item-title {{cal.titre}}
  div.banner()
    div.b.date-banner.items-center.justify-center(v-if='selectedEvent.start && !selectedEvent.end')
      span {{selectedEvent.start}}
      span {{getDate(selectedEvent.start)}}
    div.time-banner.flex.items-center.justify-center(v-else)
      span {{getDate(selectedEvent.start)}}
      font-awesome-icon.f4.mh3(:icon="['far', 'long-arrow-right']")
      span {{getDate(selectedEvent.end)}}
    div.time-banner.flex.items-center.justify-center(v-if='selectedEvent.timed')
      span {{getTime(selectedEvent.start)}}
      font-awesome-icon.f4.mh3(:icon="['far', 'long-arrow-right']")
      span {{getTime(selectedEvent.end)}}

  v-card-text
    span(v-if='selectedEvent.data[0] && selectedEvent.data[0].description', v-html='selectedEvent.data[0].description')
    div.mt2(v-if='selectedEvent.type === "devoir"')
      template(v-if='selectedEvent.data.length')
        v-chip(v-if='selectedEvent.data.length', outlined, small)
          span {{selectedEvent.data.length}} {{selectedEvent.data.length > 1 ? "exercices" : "exercice"}}
        v-chip.ml2(v-if='selectedEvent.data.length', outlined, small)
          span {{ selectedEvent.data.map(e => e.elementsUsagers.total).reduce((a, b) => a + b, 0) }} {{selectedEvent.data.map(e => e.elementsUsagers.total).reduce((a, b) => a + b, 0).length > 1 ? 'élèves' : 'élève'}}
    //- div.mt2(v-else) {{selectedEvent}}
    v-btn.mt2(v-if='selectedEvent.type === "devoir"', :to="'/devoirs/' + selectedEvent.batch", rounded, block, small, outlined, color='secondary') Aller sur le devoir
    //- v-btn(rounded, block, small, outlined, color='secondary') Action contextuelle
  //- v-card-actions
    v-btn(text, color='secondary', @click='$emit("close")')
      | Fermer
</template>
<style lang='sass'>
  @import 'src/styles/components/presentation/_eventCard'

</style>
